import '@babel/polyfill';
import { matchHeight } from './matchHeigth';
jQuery(function ($) {
    console.log('test.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
const kvSwiper = new Swiper('.kv-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.kv-pagination',
        clickable: true
    }
});
const newsSwiper = new Swiper('.news-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 15,
    centeredSlides: true,
    loop: true,
    pagination: {
        el: '.news-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.Swiper-button-next--news',
        prevEl: '.Swiper-button-prev--news',
        hideOnClick: true
    },
    speed: 1000,
    breakpoints: {
        767: {
            slidesPerView: 3,
            spaceBetween: 40,
            centeredSlides: false
        }
    }
});
const montBlancSwiper = new Swiper('.montBlanc-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const tipsSwiper = new Swiper('.tips-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const giftSwiper = new Swiper('.gift-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const sweetPotatoMatch = document.querySelector('.sweetPotato-match');
const sweetPotatoAlign = document.querySelectorAll('.sweetPotato-align');
window.addEventListener('load', () => {
    matchHeight(sweetPotatoMatch, sweetPotatoAlign);
});
window.addEventListener('resize', () => {
    matchHeight(sweetPotatoMatch, sweetPotatoAlign);
});
const purineMatch = document.querySelector('#purine-match');
const purineAlign = document.querySelectorAll('#purine-align');
window.addEventListener('load', () => {
    matchHeight(purineMatch, purineAlign);
});
window.addEventListener('resize', () => {
    matchHeight(purineMatch, purineAlign);
});
const { createClient } = microcms;
const client = createClient({
    serviceDomain: 'fukunoimo',
    apiKey: 'G7iLBIWUQewcbkNjPcWl8zzPu3H7Gr0Mp0Ok',
});
window.addEventListener('DOMContentLoaded', () => {
    const topNewsArea = document.querySelector('#js-top-news');
    if (topNewsArea) {
        client.get({
            endpoint: 'news',
            queries: { limit: 10 }
        }).then(res => {
            if (res.contents.length > 0) {
                const newsList = res.contents.map(news => {
                    return `
            <li class="p-news__slider__swiper-wrapper__slide swiper-slide">
              <a href="./news" data-article-id="${news.id}">
                <div class="p-news-card">
                  <div class="p-news-card__image-box">
                    <img src="${news.photo.url}" alt="">
                  </div>
                  <div class="p-news-card__contents-box">
                    <div class="p-news-card__contents-box__data u-mt2">
                      <p class="p-news-card__contents-box__data__date">${new Date(news.publishedAt).toLocaleDateString()}</p>
                      <p class="p-news-card__contents-box__data__cate">${news.category?.name || '未分類'}</p>
                    </div>
                    <div class="c-sentence u-mt2">
                      <p class="c-sentence__item">${news.title}</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          `;
                }).join('');
                topNewsArea.innerHTML = newsList;
                document.querySelectorAll('.p-news__slider__swiper-wrapper__slide a').forEach(link => {
                    link.addEventListener('click', function (e) {
                        const articleId = this.dataset.articleId;
                        localStorage.setItem('scrollTarget', articleId);
                    });
                });
            }
            else {
                topNewsArea.innerHTML = '<p>現在、ニュースはありません。</p>';
            }
        }).catch(err => {
            console.error('ニュースの取得に失敗しました:', err);
            topNewsArea.innerHTML = '<p>ニュースを取得できませんでした。</p>';
        });
    }
    const archiveNewsArea = document.querySelector('#js-archive-news');
    if (archiveNewsArea) {
        fetchNewsData();
    }
});
function fetchNewsData() {
    const archiveNewsArea = document.querySelector('#js-archive-news');
    return client.get({
        endpoint: 'news',
        queries: { limit: 100 }
    }).then(res => {
        if (res.contents.length > 0) {
            const newsList = res.contents.map(news => {
                return `
          <li class="p-news-archive__item" id="${news.id}">
            <p class="p-news-archive__news-title">${news.title}</p>
            <div class="p-news-archive__data">
              <p class="date">${new Date(news.publishedAt).toLocaleDateString()}</p>
              <p class="cate">${news.category?.name || '未分類'}</p>
            </div>
            <div class="p-news-archive__contents-inner">
              <div class="p-news-archive__image-box"><img src="${news.photo.url}" alt=""></div>
              <div class="p-news-contents">
                ${news.content}
              </div>
            </div>
          </li>
        `;
            }).join('');
            archiveNewsArea.innerHTML = newsList;
        }
        else {
            archiveNewsArea.innerHTML = '<p>現在、ニュースはありません。</p>';
        }
        handleScrollToTarget();
    }).catch(err => {
        console.error('ニュースの取得に失敗しました:', err);
        archiveNewsArea.innerHTML = '<p>ニュースを取得できませんでした。</p>';
    });
}
function handleScrollToTarget() {
    const scrollTarget = localStorage.getItem('scrollTarget');
    if (scrollTarget) {
        const checkExist = setInterval(() => {
            const target = document.getElementById(scrollTarget);
            if (target) {
                clearInterval(checkExist);
                target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                localStorage.removeItem('scrollTarget');
            }
        }, 300);
    }
}
