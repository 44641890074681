import '@babel/polyfill';
import { matchHeight } from './matchHeigth';
jQuery(function ($) {
    console.log('test.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
const kvSwiper = new Swiper('.kv-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.kv-pagination',
        clickable: true
    }
});
const newsSwiper = new Swiper('.news-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 15,
    centeredSlides: true,
    loop: true,
    pagination: {
        el: '.news-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.Swiper-button-next--news',
        prevEl: '.Swiper-button-prev--news',
        hideOnClick: true
    },
    speed: 1000,
    breakpoints: {
        767: {
            slidesPerView: 3,
            spaceBetween: 40,
            centeredSlides: false
        }
    }
});
const montBlancSwiper = new Swiper('.montBlanc-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const tipsSwiper = new Swiper('.tips-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const giftSwiper = new Swiper('.gift-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});
const sweetPotatoMatch = document.querySelector('.sweetPotato-match');
const sweetPotatoAlign = document.querySelectorAll('.sweetPotato-align');
window.addEventListener('load', () => {
    matchHeight(sweetPotatoMatch, sweetPotatoAlign);
});
window.addEventListener('resize', () => {
    matchHeight(sweetPotatoMatch, sweetPotatoAlign);
});
const purineMatch = document.querySelector('#purine-match');
const purineAlign = document.querySelectorAll('#purine-align');
window.addEventListener('load', () => {
    matchHeight(purineMatch, purineAlign);
});
window.addEventListener('resize', () => {
    matchHeight(purineMatch, purineAlign);
});
function scrollToAnchor(anchorId) {
    let headerHeight = document.querySelector("header").offsetHeight;
    let target = document.querySelector(anchorId);
    if (target) {
        let targetPosition = target.getBoundingClientRect().top + window.pageYOffset - headerHeight - 100;
        window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
}
window.addEventListener("load", function () {
    let anchorId = window.location.hash;
    if (anchorId) {
        scrollToAnchor(anchorId);
    }
});
let anchorLinks = document.querySelectorAll("a[href^='#']:not([href='#'])");
for (let link of anchorLinks) {
    link.addEventListener("click", function (e) {
        e.preventDefault();
        let anchorId = this.getAttribute("href");
        scrollToAnchor(anchorId);
        history.pushState(null, null, anchorId);
    });
}
