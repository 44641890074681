export const matchHeight = (match, align) => {
    const clientW = window.innerWidth;
    const spSize = 768;
    if (clientW < spSize)
        return;
    const targetH = match.getBoundingClientRect();
    for (let i = 0; i < align.length; i++) {
        align[i].style.height = targetH.height + 'px';
    }
};
